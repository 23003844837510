/*
	Accessibility Buttons
	Version: 2.0.0
	Author: Tiago Porto - http://www.tiagoporto.com
	https://github.com/tiagoporto
	Contact: me@tiagoporto.com
*/
/* Typography */
body {
    font-size: 1em;
    line-height: 1.1875em;
  }
  
  /* Transition */
  body {
    -webkit-transition: all 1s ease-in;
    -moz-transition: all 1s ease-in;
    -o-transition: all 1s ease-in;
    transition: all 1s ease-in;
  }
  
  /* Accessibility */
  .accessibility-font {
    font-size:1.25em;
    font-size:1.25rem
  }
  
  .accessibility-contrast {
    font-weight:bold;
    text-shadow:1px 1px 1px grey; /* CSS3 */
    filter: Shadow(Color=grey, Direction=130, Strength=1); /* IE Proprietary Filter*/
  }
  